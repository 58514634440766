<template>
  <div>
    <p class="title" v-if="this.$route.params.list==2"><el-link type="primary" :underline="false" @click="homepage(1)" class="head">商品列表</el-link> >> <span>编辑商品信息</span></p>
    <p class="title" v-if="this.$route.params.list==1"><el-link type="primary" :underline="false" @click="homepage(2)" class="head">商品仓库</el-link> >> <span>编辑商品信息</span></p>
    <p class="title" v-if="this.$route.params.list==3"><el-link type="primary" :underline="false" @click="homepage(3)" class="head">人工代充</el-link> >> <span>编辑商品信息</span></p>
    <p class="title" v-if="this.$route.params.list==4"><el-link type="primary" :underline="false" @click="homepage(4)" class="head">商户商品</el-link> >> <el-link type="primary" :underline="false" @click="homepage(5)" class="head">{{this.$route.params.row.good_name}}</el-link> >><span>编辑</span></p>
      <div style="border:gray 1px solid;width:98%;margin:10px auto;height:95%">
      <p style="text-align:left;border-bottom:black 1px dashed;paddingTop:10px;width:95%;margin:0 auto"><span style="color:blue;padding-left: 20px">商品信息</span></p>
            <table style="text-align:left">
              <tr style="height:35px"><td class="td1">商品编码：</td><td></td><td>{{coding}}</td></tr>
              <tr style="height:35px"><td class="td1">所属供应商：</td><td></td><td>{{supplierStr}}</td></tr>
              <tr style="height:35px"><td class="td1">现商品名称：</td><td></td><td><span>{{goods_name}}</span><span class="chan" @click="edit">修改</span></td>
              </tr>  
               <tr style="height:35px"><td class="td1">原供应商商品名称：</td><td></td><td>{{good_name}}</td></tr> 
            </table>
              <p style="text-align:left;border-bottom:black 1px dashed;margin:15px auto;width:95%"><span style="color:blue;padding-left: 20px">商品价格</span></p>
              <el-form ref="form" :model="form" label-width="130px" style="margin-left:30px">
                <el-form-item label="进价：" style="height:10px;margin-left:10px">
                  <el-input v-model="form.org_price" placeholder="请设置进价" type="number" min="0" style="width:150px;float:left;"></el-input>
                </el-form-item>
                <br />
                <el-form-item label="起充数量：" style="height:10px;margin:10px 0 10px 10px">
                  <el-input v-model="form.attr_num"  type="number" min="0" style="width:150px;float:left"></el-input>
                </el-form-item>
                <br />
                <el-form-item label="商品销售价：" style="height:10px;margin:20px 0 10px 10px">
                  <el-input v-model="form.price" placeholder="请设置销售价" type="number" min="0" style="width:150px;float:left"></el-input>
                </el-form-item>
                <br />
                <!-- <el-form-item label="商品价格上限：" style="height:10px;margin:20px 0 10px 10px" prop="maxprice" :rules="[{ required: true, message: '请输入价格上限', trigger: 'blur' }]">
                  <el-input v-model="form.maxprice" placeholder="请设置价格上限" type="number" min="0" style="width:150px;float:left"></el-input>
                  <span style="color:red;float:left;padding-left:10px">(当进价更新时若高于价格上限该商品会自动下架)</span>
                </el-form-item>                  -->
                <el-form-item label="官方价格：" style="height:10px;margin:20px 0 10px 10px" prop="maxprice" :rules="[{ required: true, message: '请输入官方价格', trigger: 'blur' }]">
                  <el-input v-model="form.maxprice" placeholder="请设置官方价格" type="number" min="0" style="width:150px;float:left"></el-input>
                </el-form-item> 
              </el-form>
              <br />
        <p style="text-align:left;border-bottom:black 1px dashed;margin:15px auto;width:95%"><span style="color:blue;padding-left: 20px">商品品牌分类及规格</span></p>
            <el-form ref="forms" :model="forms" label-width="130px" style="margin-left:30px">
                <el-form-item label="商品品牌分类：" style="height:10px;margin:20px 0 10px 10px" prop="classify" :rules="[{ required: true, message: '请选择商品品牌分类', trigger: 'blur' }]">
                <el-cascader placeholder="请选择商品品牌分类" :options="class_options" :props="defaultParams" :show-all-levels="false"  v-model=" forms.classify"  clearable name='cla' style="width:200px;text-align: center;float:left"></el-cascader>
                </el-form-item>
                <br />
                <el-form-item label="商品数量规格：" style="margin:20px 0 10px 10px">
                    <el-radio-group v-model="radio" style="float:left;padding-top:1.3%">
                        <el-radio :label="1">单数量</el-radio>
                        <el-radio :label="2">多数量</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label=" 账号充值上限：" style="height:10px;margin:10px 0 10px 10px">
                  <el-input v-model="accmax"  type="number" min="0" placeholder="该数量为账号充值数" style="width:180px;float:left"></el-input>
                </el-form-item>
                <br />
                <el-form-item label=" 单量限制设置：" style="height:10px;margin:10px 0 10px 10px">
                    <el-switch
                    style="float:left;padding-top:20px"
                    v-model="state"
                    :active-value=1
                    :inactive-value=2>
                    </el-switch>
                  <el-input v-model="ordernum"  type="number" min="0" v-if="state==1" placeholder="该数量为单量限制" style="width:180px;float:left;margin-left:10px"></el-input>
                </el-form-item>
            </el-form>
            <br />
            <div><el-button type="primary"  @click="submit">提交</el-button>
            </div>
            </div>
            <el-dialog title="设置商品名称" :visible.sync="dialogFormVisible" width="30%" center>
              <label for="goodname" style="paddingLeft:90px">商品名称：</label>
              <el-input v-model="goods_names" placeholder="请输入商品名称" style="width:60%" name="goodname"></el-input>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="ok">确 定</el-button>
              </div>
            </el-dialog>
  </div>
</template>

<script>
export default {
  // inject:['chanlist'],
    data() {
        return {
          good_name:"",
          supplierStr:"",
          coding:"",
            goods_names:"",
            dialogFormVisible:false,
            ordernum:"1",
            goods_name:"",
            message:"",
            state:2,
            accmax:"1",
            radio:1,
            class_options:[],
        forms:{
            classify:"",
        },
        form:{
            maxprice:"",
            price:"",//销售价
            attr_num:"",//起充数量
            org_price:'',//进价
            },
            defaultParams: {
                label: 'brand_name',
                value: 'id',
                children: 'children',
                emitPath:false,
                checkStrictly: true,
            },  
        }
    },
    mounted() { 
      // this.chanlist("1-1",'1')
      if (this.$route.params.row==undefined) {
        this.$router.push({path:"/homes/merchant_order"})
        return false
      }
        this.getclass()
        this.getdetial()
        },
    methods: {
      homepage(page){
        if (page==2) {
           this.$router.push({path:"/homes/merchant_order"})
        }else if(page==1){
          this.$router.push({name:"Goods_list",params:{b:this.$route.params.b,a:this.$route.params.a,id:this.$route.params.id,ref:2,g_name:this.$route.params.g_name}})
        }else if (page==3) {
           this.$router.push({path:"/homes/artificial"})
        }else if (page==4) {
           this.$router.push({name:"Merchant_goods",params:{mid:this.$route.params.mid}})
        }else if (page==5) {
           this.$router.push({name:"Goodsconfig",params:{mid:this.$route.params.mid,a:this.$route.params.a,id:this.$route.params.id,}})
        }
      },
      getdetial(){
        this.$axios.post("/supplier/good/get",{gid:this.$route.params.row.id}).then((res)=>{
          if (res.data.code==20000) {
            this.goods_name=res.data.data.good_name
            this.good_name=res.data.data.supplier_good_name
            this.coding=res.data.data.coding
            // this.form.maxprice=res.data.data.price_cap
            this.form.maxprice=res.data.data.official_price
            this.form.org_price=res.data.data.purchase_price
            this.form.price=res.data.data.price
            this.supplierStr=res.data.data.supplierStr
            if (res.data.data.brand_id==0) {
               this.forms.classify=''
            }else{
            this.forms.classify=res.data.data.brand_id
            }
            this.radio=res.data.data.specs
            this.state=res.data.data.single_quantity_limit
            this.ordernum=res.data.data.single_quantity_limit_num
            this.accmax=res.data.data.account_recharge_limit
            this.form.attr_num=res.data.data.start_charging
          }
        })
      },
        edit(){
            this.goods_names=this.goods_name
            this.dialogFormVisible=true
        },
        ok(){
            this.goods_name=this.goods_names
            this.dialogFormVisible=false
        },
          getclass(){
       this.$axios.post('/supplier/good/list/brand',{type:1}).then((res)=>{
         this.class_options=res.data.data
          })
      },
        submit(){
            if (this.form.org_price=='') {
                this.$message({
                    message: "请设置商品进价",
                    type: 'warning',
                    center:'true',
                 });
                 return false
            }
            if (this.form.attr_num=='') {
                this.$message({
                    message: "请设置起充数量",
                    type: 'warning',
                    center:'true',
                 });
                 return false
            }
            if (this.form.price=='') {
                this.$message({
                    message: "请设置销售价格",
                    type: 'warning',
                    center:'true',
                 });
                 return false
            }
            if (this.form.maxprice=='') {
                this.$message({
                    message: "请设置商品价格上限",
                    type: 'warning',
                    center:'true',
                 });
                 return false
            }
            if (this.forms.classify=='') {
                this.$message({
                    message: "请设置商品品牌分类",
                    type: 'warning',
                    center:'true',
                 });
                 return false
            }
          
            //      if (this.state==1) {
            //     if (this.ordernum==0){
            //         this.$message({
            //         message: "请设置单量限制",
            //         type: 'warning',
            //         center:'true',
            //      });
            //      return false
            //     }
            //  }
            let params={gid:this.$route.params.row.id,good_name:this.goods_name,price:this.form.price,purchase_price:this.form.org_price,
            start_charging:this.form.attr_num,official_price:this.form.maxprice,brand_id:this.forms.classify,specs:this.radio,account_recharge_limit:this.accmax,
            single_quantity_limit:this.state,single_quantity_limit_num:this.ordernum}
            this.$axios.post('/supplier/good/edit',params).then((res)=>{
                if (res.data.code==20000) {
                    this.$message({
                    message: res.data.message,
                    type: 'success',
                    center:'true',
                 });
                 if (this.$route.params.list==1) {
                      this.$router.push({path:"/homes/merchant_order"})
                   }else if(this.$route.params.list==2){
                     this.$router.push({name:"Goods_list",params:{b:this.$route.params.b,a:this.$route.params.a,id:this.$route.params.id,ref:2,g_name:this.$route.params.g_name}})
                   }else if (this.$route.params.list==3) {
                      this.$router.push({path:"/homes/artificial"})
                   }else if (this.$route.params.list==4) {
                      this.$router.push({name:"Merchant_goods",params:{mid:this.$route.params.mid}})
                   }else if (this.$route.params.list==5) {
                      this.$router.push({name:"Goodsconfig",params:{mid:this.$route.params.mid,a:this.$route.params.a,id:this.$route.params.id,}})
                   }
                }else{
                    this.$message({
                    message: res.data.message,
                    type: 'warning',
                    center:'true',
                 });
                }
            })
        },
        },
}
</script>

<style scoped lang="less">
.td1{width: 150px;text-align: right;}
.chan{color: blue; cursor: pointer;padding-left: 10px;}
.title{height: 40px;line-height: 40px;padding-left: 20px;border-bottom: 1px solid black;text-align: left;
> span{font-size: 16px;color: black;}}
.head{font-size: 20px;}
</style>